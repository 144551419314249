(function ( $ ) {
     $.fn.registerNewAccount = function (options) {
        var objLink   = $(this);

        function _init() {

            $(objLink).on('ifCreated', function(event){
                val = $(this).prop('checked');
                if(val === false){
                    $('.create-account').addClass('hidden');
                }else{
                    $('.create-account').removeClass('hidden');
                }
            });

            $(objLink).on('ifChanged', function(event){
                val = $(this).prop('checked');
                console.log(val);
                if(val === false){
                    $('.create-account').addClass('hidden');
                }else{
                    $('.create-account').removeClass('hidden');
                }
            });
        }

        _init();

       return {
         reload:_init,
       };
    };
}( jQuery ));

$('#createaccount').registerNewAccount();

(function ( $ ) {
     $.fn.addSecondShippingAdress = function (options) {
        var objLink   = $(this);

        function _init() {
            $(objLink).on('ifCreated', function(event){
                val = $(this).prop('checked');
                if(val === false){
                    $('.shipping_address').addClass('hidden');
                }else{
                    $('.shipping_address').removeClass('hidden');
                }
            });

            $(objLink).on('ifChanged', function(event){
                val = $(this).prop('checked');
                console.log(val);
                if(val === false){
                    $('.shipping_address').addClass('hidden');
                }else{
                    $('.shipping_address').removeClass('hidden');
                }
            });
        }

        _init();

       return {
         reload:_init,
       };
    };
}( jQuery ));
$('#ship-to-different-address-checkbox').addSecondShippingAdress();
